<template>
  <div>
    <v-row class="justify-center align-center">
      <v-col
        cols="12"
        md="3"
        class="d-none d-lg-flex d-md-none justify-center align-center"
        style="height: 100vh"
      >
        <img :src="phone" style="max-width: 100%" />
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="d-flex justify-center align-center"
        style="height: 100vh"
      >
        <v-row class="py-10 px-5">
          <v-col cols="12">
            <v-card class="mx-auto my-2 px-5 py-5" outlined style="width: 92%">
              <v-img :src="logo" class="mx-auto" style="max-width: 175px">
              </v-img>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="name"
                  :counter="10"
                  :rules="nameRules"
                  label="Senha"
                  required
                ></v-text-field>
                <v-btn
                  block
                  color="secondary"
                  elevation="2"
                  class="mt-5 a-login "
                  to="/time_line"
                  >Entrar</v-btn
                >

                <v-row class="mt-2 justify-center align-center">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="3" class="text-center"> OU </v-col>
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12"
                    ><v-btn block color="info" elevation="2">
                      <i class="fab fa-facebook-square mx-3"></i> Entrar com o
                      Facebook</v-btn
                    ></v-col
                  >
                  <v-col cols="12" class="text-center"
                    ><router-link to="/password/rest" custom
                      >Esqueceu a senha?</router-link
                    ></v-col
                  >
                </v-row>
              </v-form>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto px-2 py-2 text-center" outlined style="width: 92%">
              Não tem uma conta?
              <router-link to="/signup" custom>Cadastre-se</router-link>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Footer />
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: require("@/assets/logo.png"),
      phone: require("@/assets/phone.png"),
      valid: true,
      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
};
</script>